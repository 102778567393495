import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import enGBLocale from 'date-fns/locale/en-GB'
import React from 'react'
import DatePicker from './date.picker'
import DateRangePicker from './date.range.picker'
import DateRangePickerForForm from './date.range.picker.form'
import { IDateFilterProps, useDatePicker } from './use.date.picker'
import {
  IDateRangeFilterProps,
  useDateRangePicker,
} from './use.date.range.picker'

export const DateRangeFilter = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  startDateLabel,
  endDateLabel,
  dateStructure,
  isFutureAllowed,
  error,
  isCustomStyle,
}: IDateRangeFilterProps) => {
  const [
    formatCurrentValue,
    errorHandler,
    handleStartDateChange,
    handleEndDateChange,
  ] = useDateRangePicker({
    setStartDate,
    setEndDate,
    dateStructure,
  })

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={enGBLocale}
      >
        <DateRangePicker
          setDateLimit={formatCurrentValue}
          startDate={startDate}
          endDate={endDate}
          errorHandler={errorHandler}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          startDateLabel={startDateLabel}
          endDateLabel={endDateLabel}
          dateStructure={dateStructure}
          error={error}
          isFutureAllowed={isFutureAllowed}
          isCustomStyle={isCustomStyle}
        />
      </LocalizationProvider>
    </>
  )
}

export const DateFilter: React.FC<any> = ({
  date,
  setDate,
  dateLabel,
  dateStructure,
  ...rest
}: IDateFilterProps) => {
  const [errorHandler, handleDateChange] = useDatePicker({
    setDate,
    dateStructure,
  })

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          errorHandler={errorHandler}
          date={date}
          handleDateChange={handleDateChange}
          dateLabel={dateLabel}
          dateStructure={dateStructure}
          {...rest}
        />
      </LocalizationProvider>
    </>
  )
}

export enum DateEnum {
  startDate = 'startDate',
  endDate = 'endDate',
}

export const DateRangePickerForm: React.FC<any> = ({
  handleDateChange,
  startDateLabel,
  endDateLabel,
  dateStructure,
  disablePast,
  disableFuture,
  register,
  getValues,
}: any) => (
  <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePickerForForm
        startDateLabel={startDateLabel}
        endDateLabel={endDateLabel}
        dateStructure={dateStructure}
        disablePast={disablePast}
        disableFuture={disableFuture}
        register={register}
        getValues={getValues}
        handleDateChange={handleDateChange}
      />
    </LocalizationProvider>
  </>
)
